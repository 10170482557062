import React, {useEffect, useState} from "react";
import {AppBar, Box, CircularProgress, Dialog, FormControlLabel, FormGroup, IconButton, Menu, MenuItem,
    Switch, Toolbar,
} from "@mui/material";
import AccountCircle from '@mui/icons-material/AccountCircle';
import {useNavigate} from "react-router-dom";
import axios from "axios";
import PercentIcon from '@mui/icons-material/Percent';
import SettingsIcon from '@mui/icons-material/Settings';

const Appbar = ({setDialogPecent, setDialogInstallment ,showPrice , setShowPrice}) => {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [role, setRole] = useState(null);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        const data = {
            token: sessionStorage.getItem("token"),
        }
        axios.post("https://khaneaghsat.co/api/userinfo.php", data)
            .then(response => {
                if (response.status === 200) {
                    if (response.data.error === false) {
                        setRole(response.data.role);
                    } else {
                        if (response.data.ex_token) {
                            sessionStorage.clear();
                            navigate("/admin/login");
                        }
                    }
                }
            })
            .catch(error => {
            })
    }, [])
    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    return (
        <Box sx={{flexGrow: 1, marginTop: "-16px"}}>
            <AppBar position="static" sx={{backgroundColor: "#2b2b2b"}}>
                <Toolbar variant="dense">
                    <Box dir={"rtl"}>
                        <IconButton dir={"ltr"}>
                            <FormGroup>
                                <FormControlLabel
                                    sx={{color: "white"}} control={
                                    <Switch
                                        onChange={(event)=>setShowPrice(event.target.checked)}
                                        checked={showPrice}
                                        color={"info"}/>
                                } label="نمایش مبلغ کل"/>
                            </FormGroup>
                        </IconButton>
                        <IconButton
                            size="small"
                            onClick={() => setDialogInstallment(true)}
                            color="inherit">
                            <SettingsIcon/>
                        </IconButton>
                        <IconButton
                            size="small"
                            onClick={() => setDialogPecent(true)}
                            color="inherit">
                            <PercentIcon/>
                        </IconButton>
                        <IconButton
                            size="small"
                            onClick={handleMenu}
                            color="inherit">
                            <AccountCircle/>
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorEl)}
                            onClose={() => setAnchorEl(null)}
                        >
                            <MenuItem sx={{fontSize: "14px"}} onClick={() => {
                                sessionStorage.clear()
                                navigate('/admin/login');
                            }}>خروج از حساب کاربری</MenuItem>
                        </Menu>
                    </Box>
                </Toolbar>
            </AppBar>
            <Dialog
                open={loading}
                scroll="paper">
                <CircularProgress sx={{margin: 2}}/>
            </Dialog>
        </Box>
    )
}
export default Appbar