import React, {useEffect, useState} from "react";
import {Box, Divider, FormControl, Grid, InputLabel, ListSubheader, MenuItem, Select, Slider} from "@mui/material";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import InfoIcon from '@mui/icons-material/Info';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import axios from "axios";

const allcolor = [
    {"id": 0, "color": "#f1f1f1", "title": "همه رنگ ها"}
]
const Home = () => {
    const [loading, setLoading] = useState(false);
    const [selectProduct, setSelectProduct] = useState(-1);
    const [productList, setProductList] = useState([]);
    const [selectModel, setSelectModel] = useState("");
    const [modelList, setModelList] = useState([]);
    const [selectStorage, setSelectStorage] = useState("");
    const [storageList, setStorageList] = useState([]);
    const [selectColor, setSelectColor] = useState("");
    const [colorList, setColorList] = useState([]);
    const [price, setPrice] = useState(0);
    const [prePay, setPrePay] = useState(0);
    const [prePayList, setPrePayList] = useState([]);
    const [monthPay, setMonthPay] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [installment, setInstallment] = useState(0);
    const [installmentList, setInstallmentList] = useState([]);
    const [percentList, setPercentList] = useState("");
    const [active, setActive] = useState(false);
    const [showPrice, setShowPrice] = useState(false);
    useEffect(() => {
        axios.post("https://khaneaghsat.co/api/get_productlist.php")
            .then(response => {
                setLoading(false);
                if (response.status === 200) {
                    console.log(response.data);
                    if (response.data.error === false) {
                        setProductList(response.data.data);
                        setPercentList(response.data.percent);
                        setInstallmentList(response.data.installment);
                        setShowPrice(response.data.show_price);
                    } else {
                        toast.error(response.data.message);
                    }
                } else {
                    toast.error("خطا در ارتباط با سرور");
                }
            })
            .catch(error => {
                toast.error("خطا در ارتباط با سرور");
                setLoading(false);
            })
    }, [])
    const handle_change_product = (e) => {
        setStorageList([]);
        setInstallment("");
        setPrePay(0);
        setPrePayList([]);
        setModelList([]);
        setSelectStorage("");
        setSelectModel("");
        setTotalPrice(0);
        setPrice(0);
        setMonthPay(0);
        setSelectProduct(e.target.value);
        if (e.target.value !== -1) {
            setModelList(productList[e.target.value].model);
        } else {
            setModelList([]);
        }
    }
    const handle_change_model = (e) => {
        setStorageList([]);
        setSelectStorage("");
        setPrePayList([]);
        setPrePay("");
        setInstallment("");
        setPrice("");
        setMonthPay("");
        setTotalPrice("");
        setSelectColor("");
        setSelectModel(e.target.value);
        if (e.target.value !== -1) {
            setStorageList(modelList[e.target.value].storage);
        } else {
            setStorageList([]);
        }
    }
    const handle_change_storage = (e) => {
        setPrePayList([]);
        setPrePay("");
        setInstallment("");
        setPrice("");
        setMonthPay("");
        setTotalPrice("");
        setSelectColor("");
        if (e.target.value !== -1) {
            setSelectStorage(e.target.value);
            setColorList(storageList[e.target.value].color);
        }
    }
    const handle_change_color = (list) => {
        setSelectColor(list?.id);
        setPrice(list?.price);
        setPrePayList(list?.preprice);
    }
    useEffect(() => {
        axios.get("https://raumawood.com/GHEST_ACTIVE.php")
            .then(response => {
                setActive(response.data.active);
            })
    }, [])
    useEffect(() => {
        if (installment > 0 && prePay > 0) {
            let percents = 0;
            for (const {percent, from, to} of percentList) {
                if ((Number(price) - Number(prePay)) <= to) {
                    percents = Number(percent);
                    break;
                }
            }
            let percent1 = ((Number(price) - Number(prePay)) * (Number(percents) * Number(installment))) / 100;
            setMonthPay(((Number(percent1) + Number(price)) - Number(prePay)) / Number(installment));
            setTotalPrice(Number(percent1) + Number(price));
        }

    }, [price, prePay, installment])
    return (
        <div style={{display: active ? "block" : "none"}}>
            <Box className="container">
                <div style={{padding: "5px"}}>
                    <Box sx={{
                        padding: "10px",
                        backgroundColor: "#f3f6f7",
                        borderRadius: "10px",
                    }}>
                        <div>
                            <h4 style={{margin: 0}}>محاسبه فروش قسطی</h4>
                        </div>
                        <div style={{textAlign: "right", marginTop: "5px", fontSize: "13px", color: "#2f2f2f"}}>
                            انتخاب کالا
                        </div>
                        <Select
                            fullWidth
                            size={"small"}
                            onChange={handle_change_product}
                            defaultValue={-1}>
                            <MenuItem value={-1}>
                                <em>یک مورد را انتخاب کنید</em>
                            </MenuItem>
                            {productList.map((item, index) => (
                                <MenuItem value={index}>{item.title}</MenuItem>
                            ))}
                        </Select>
                        <div style={{textAlign: "right", marginTop: "10px", fontSize: "13px", color: "#2f2f2f"}}>
                            انتخاب مدل
                        </div>
                        <Select
                            fullWidth
                            size={"small"}
                            onChange={handle_change_model}
                            onOpen={(e) => {
                                if (selectProduct === -1) {
                                    toast.error("ابتدا کالا را انتخاب کنید");
                                }
                            }}
                            defaultValue="-1">
                            {modelList.map((items, index) => (
                                <MenuItem value={index}>{items.title}</MenuItem>
                            ))}
                        </Select>
                        <div style={{textAlign: "right", marginTop: "10px", fontSize: "13px", color: "#2f2f2f"}}>
                            انتخاب حافظه
                        </div>
                        <Select
                            fullWidth
                            value={selectStorage}
                            onChange={handle_change_storage}
                            onOpen={(e) => {
                                if (selectModel <= -1 || selectModel === "") {
                                    toast.error("ابتدا مدل کالا را انتخاب کنید");
                                }
                            }}
                            size={"small"}
                            defaultValue={-1}>
                            {storageList.map((items, index) => (
                                <MenuItem value={index}>{items.title}</MenuItem>
                            ))}
                        </Select>
                        <div style={{textAlign: "right", marginTop: "5px", fontSize: "13px", color: "#2f2f2f"}}>
                            انتخاب رنگ
                        </div>
                        <Grid container spacing={0} dir={"rtl"}
                              sx={{marginTop: "0px !important", paddingTop: "0px !important"}}>
                            {selectStorage !== "" ?
                                <>
                                    {colorList.map(items => (
                                        <Grid item sx={{marginTop: "0px !important", paddingTop: "0px !important"}}>
                                            <div
                                                onClick={() => {
                                                    handle_change_color(items);
                                                }}
                                                className={selectColor === items.id ? "select_color selected_color" : "select_color"}>
                                                <div
                                                    style={{backgroundColor: items.code}}
                                                    className={"color"}>
                                                </div>
                                                <div style={{fontSize: "10px", marginTop: "5px"}}>
                                                    {items.title}
                                                </div>
                                            </div>
                                        </Grid>
                                    ))}
                                </>
                                :
                                <Grid item xs sx={{marginTop: "0px !important", paddingTop: "0px !important"}}>
                                    <div
                                        onClick={() => {
                                            if (selectStorage <= -1 || selectStorage === "") {
                                                setSelectColor("");
                                            } else {
                                                toast.error("ابتدا حافظه را مشخص کنید");
                                            }
                                        }}
                                        className={selectColor === -1 ? "select_color selected_color" : "select_color"}>
                                        <div
                                            style={{backgroundColor: "#ffffff"}}
                                            className={"color"}>
                                        </div>
                                        <div style={{fontSize: "10px", marginTop: "5px"}}>
                                            همه رنگ ها
                                        </div>
                                    </div>
                                </Grid>
                            }
                        </Grid>
                        <Grid container dir={"rtl"} spacing={2}>
                            <Grid item xs={6}>
                                <div
                                    style={{textAlign: "right", marginTop: "5px", fontSize: "13px", color: "#2f2f2f"}}>
                                    مبلغ پیش پرداخت
                                </div>
                                <Select
                                    fullWidth
                                    size={"small"}
                                    onChange={(e) => {
                                        setPrePay(e.target.value);
                                    }}
                                    value={prePay}
                                    onOpen={(e) => {
                                        if (selectColor === "") {
                                            toast.error("ابتدا رنگ را انتخاب کنید");
                                        }
                                    }}
                                    defaultValue={-1}>
                                    {prePayList.map(items => (
                                        <MenuItem value={items.id}>{items.value.toLocaleString()}</MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                            <Grid item xs={6}>
                                <div
                                    style={{textAlign: "right", marginTop: "5px", fontSize: "13px", color: "#2f2f2f"}}>
                                    تعداد اقساط
                                </div>
                                <Select
                                    fullWidth
                                    value={installment}
                                    onChange={(e) => {
                                        if (prePay <= 0) {
                                            toast.error("ابتدا پیش پرداخت را تعیین کنید");
                                        } else {
                                            setInstallment(e.target.value);
                                        }
                                    }}
                                    onOpen={(e) => {
                                        if (prePay <= 0) {
                                            toast.error("ابتدا پیش پرداخت را تعیین کنید");
                                        }
                                    }}
                                    size={"small"}
                                    defaultValue={0}>
                                    {prePay > 0 ?
                                        installmentList.map(items => (
                                            <MenuItem value={items.title}>{items.title}</MenuItem>
                                        ))
                                        : null
                                    }
                                </Select>
                            </Grid>
                        </Grid>
                        <div style={{
                            backgroundColor: "#000000",
                            borderRadius: "10px",
                            padding: "10px",
                            marginTop: "5px",
                        }}>
                            {showPrice ?
                                <Grid container dir={"rtl"}>
                                    <Grid item xs={6}>
                                        <div style={{textAlign: "right", fontSize: "13px", color: "#ffffff"}}>
                                            قیمت اولیه کالا
                                        </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div style={{textAlign: "left", fontSize: "13px", color: "#ffffff"}}>
                                            {Number(price).toLocaleString()} تومان
                                        </div>
                                    </Grid>
                                </Grid>
                                :
                                null
                            }
                            <Grid container dir={"rtl"}>

                                <Grid item xs={6}>
                                    <div style={{textAlign: "right", fontSize: "13px", color: "#ffffff"}}>
                                        مبلغ هرقسط ماهانه
                                    </div>
                                </Grid>
                                <Grid item xs={6}>
                                    <div style={{textAlign: "left", fontSize: "13px", color: "#ffffff"}}>
                                        {parseInt(monthPay).toLocaleString()} تومان
                                    </div>
                                </Grid>
                                <Grid item xs={6}>
                                    <div style={{textAlign: "right", fontSize: "13px", color: "#ffffff"}}>
                                        قیمت تمام شده
                                    </div>
                                </Grid>
                                <Grid item xs={6}>
                                    <div style={{textAlign: "left", fontSize: "13px", color: "#ffffff"}}>
                                        {parseInt(totalPrice).toLocaleString()} تومان
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                        <div style={{
                            backgroundColor: "#0610ab",
                            borderRadius: "10px",
                            padding: "10px",
                            marginTop: "5px",
                            color: "white",
                            direction: "ltr",
                        }}>
                            <div> برای اطلاعات بیشتر از شرایط و مشاوره تلفنی ، تماس بگیرید<InfoIcon
                                sx={{marginBottom: -1}}/></div>

                            <a style={{color: "white", textDecoration: "none"}}
                               href="tel:+982166704132"><PhoneInTalkIcon sx={{marginBottom: -1}}/> 021-66704132</a>
                        </div>
                    </Box>
                </div>
            </Box>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={true}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    )
}
export default Home;