import React, {useEffect, useState} from "react"
import {Navigate} from "react-router-dom";
import Appbar from "./Appbar";
import {
    Box, Button, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton,
    TextField,
} from "@mui/material";
import axios from "axios";
import {toast, ToastContainer} from "react-toastify";
import EditIcon from '@mui/icons-material/Edit';
const Dashboard = () => {
    const [loading, setLoading] = useState(true);
    const [productList, setProductList] = useState([]);
    const [update, setUpdate] = useState(false);
    const [dialogStorage, setDialogStorage] = useState(false);
    const [dialogModel , setDialogModel] = useState(false);
    const [dialogColor , setDialogColor] = useState(false);
    const [dialogProduct , setDialogProduct] = useState(false);
    const [dialogPercent , setDialogPercent] = useState(false);
    const [selectedId, setSelectedId] = useState("");
    const [storageTitle, setStorageTitle] = useState("");
    const [modelTitle , setModelTitle] = useState("");
    const [productTitle , setProductTitle] = useState("");
    const [colorTitle ,setColorTitle] = useState("");
    const [colorCode , setColorCode] = useState("#000000");
    const [price , setPrice] = useState("");
    const [minPrePrice , setMinPrePrice] = useState("");
    const [maxPrePrice , setMaxPrePrice] = useState("");
    const [stepPrePrice , setStepPrePrice] = useState("");
    const [percentList , setPercentList] = useState([]);
    const [installment , setInstallment] = useState("");
    const [installmentList , setInstallmentList] = useState([]);
    const [dialogInstallment , setDialogInstallment] = useState(false);
    const [dialogEditColor , setDialogEditColor] = useState(false);
    const [showPriceSwitch , setShowPriceSwitch] = useState(false);
    useEffect(() => {
        const data = {
            token: sessionStorage.getItem("token"),
        }
        axios.post("https://khaneaghsat.co/api/get_productlist.php", data)
            .then(response => {
                setLoading(false);
                if (response.status === 200) {
                    if (response.data.error === false) {
                        setProductList(response.data.data);
                        setPercentList(response.data.percent);
                        setInstallmentList(response.data.installment);
                        setShowPriceSwitch(response.data.show_price);
                    }

                    else {
                        toast.error(response.data.message);
                        sessionStorage.clear();
                    }
                } else {
                    toast.error("خطا در ارتباط با سرور");
                }
            })
            .catch(error => {
                toast.error("خطا در ارتباط با سرور");
                setLoading(false);
            })
        setUpdate(false);
    }, [update])
    const Redirect = () => {
        return (<Navigate replace to="/admin/login"/>)
    }
    const handle_add_storage=() => {
        if (!!selectedId) {
            setLoading(true);
            const data = {
                token: sessionStorage.getItem("token"),
                section: "storage",
                title: storageTitle,
                id: selectedId,
            }
            axios.post("https://khaneaghsat.co/api/insert.php", data)
                .then(response => {
                    setLoading(false);
                    if (response.status === 200) {
                        if (response.data.error === false) {
                            setUpdate(true);
                            setStorageTitle("");
                            setDialogStorage(false);
                            setSelectedId("");
                            toast.success(response.data.message);
                        } else {
                            toast.error(response.data.message);
                        }
                    } else {
                        toast.error("خطا در اتصال به سرور");
                    }
                })
                .catch(error => {
                    setLoading(false);
                    toast.error("خطا در اتصال به سرور");
                })
        } else {
            toast.error("هیچ موردی انتخاب نشده است")
        }
    }
    const handle_add_product=()=>{
        setLoading(true);
        const data = {
            token: sessionStorage.getItem("token"),
            section: "product",
            title: productTitle,
        }
        axios.post("https://khaneaghsat.co/api/insert.php", data)
            .then(response => {
                setLoading(false);
                if (response.status === 200) {
                    if (response.data.error === false) {
                        setUpdate(true);
                        setStorageTitle("");
                        setDialogProduct(false);
                        toast.success(response.data.message);
                    } else {
                        toast.error(response.data.message);
                    }
                } else {
                    toast.error("خطا در اتصال به سرور");
                }
            })
            .catch(error => {
                setLoading(false);
                toast.error("خطا در اتصال به سرور");
            })
    }
    const handle_add_model=() => {
        if (!!selectedId) {
            setLoading(true);
            const data = {
                token: sessionStorage.getItem("token"),
                section: "model",
                title: modelTitle,
                id: selectedId,
            }
            axios.post("https://khaneaghsat.co/api/insert.php", data)
                .then(response => {
                    setLoading(false);
                    if (response.status === 200) {
                        if (response.data.error === false) {
                            setUpdate(true);
                            setModelTitle("");
                            setDialogModel(false);
                            setSelectedId("");
                            toast.success(response.data.message);
                        } else {
                            toast.error(response.data.message);
                        }
                    } else {
                        toast.error("خطا در اتصال به سرور");
                    }
                })
                .catch(error => {
                    setLoading(false);
                    toast.error("خطا در اتصال به سرور");
                })
        } else {
            toast.error("هیچ موردی انتخاب نشده است")
        }
    }
    const handle_delete=(id,section)=>{
        if (!!id && !!section){
            setLoading(true);
            const data={
                token : sessionStorage.getItem("token"),
                section : section,
                id : id,
            }
            axios.post("https://khaneaghsat.co/api/delete.php", data)
                .then(response => {
                    setLoading(false);
                    if (response.status === 200) {
                        if (response.data.error === false) {
                            setUpdate(true);
                            setStorageTitle("");
                            toast.success(response.data.message);
                        } else {
                            toast.error(response.data.message);
                        }
                    } else {
                        toast.error("خطا در اتصال به سرور");
                    }
                })
                .catch(error => {
                    setLoading(false);
                    toast.error("خطا در اتصال به سرور");
                })
        }else {
            toast.error("خطا ! هیچ موردی انتخاب نشده است");
        }
    }
    const handle_add_color=()=>{
        if (!!selectedId) {
            setLoading(true);
            const data = {
                token: sessionStorage.getItem("token"),
                section: "color",
                title: colorTitle,
                id: selectedId,
                code : colorCode,
                price : price,
                minpre : minPrePrice,
                maxpre : maxPrePrice,
                steppre : stepPrePrice,
            }
            axios.post("https://khaneaghsat.co/api/insert.php", data)
                .then(response => {
                    setLoading(false);
                    if (response.status === 200) {
                        if (response.data.error === false) {
                            setUpdate(true);
                            setColorTitle("");
                            setColorCode("");
                            setPrice("");
                            setDialogColor(false);
                            setSelectedId("");
                            toast.success(response.data.message);
                        } else {
                            toast.error(response.data.message);
                        }
                    } else {
                        toast.error("خطا در اتصال به سرور");
                    }
                })
                .catch(error => {
                    setLoading(false);
                    toast.error("خطا در اتصال به سرور");
                })
        } else {
            toast.error("هیچ موردی انتخاب نشده است")
        }
    }
    const handle_edit_color=()=>{
        if (!!selectedId) {
            setLoading(true);
            const data = {
                token: sessionStorage.getItem("token"),
                section: "color",
                title: colorTitle,
                id: selectedId,
                code : colorCode,
                price : price,
                minpre : minPrePrice,
                maxpre : maxPrePrice,
                steppre : stepPrePrice,
            }
            axios.post("https://khaneaghsat.co/api/edit.php", data)
                .then(response => {
                    setLoading(false);
                    if (response.status === 200) {
                        if (response.data.error === false) {
                            setUpdate(true);
                            setColorTitle("");
                            setColorCode("");
                            setPrice("");
                            setDialogEditColor(false);
                            setSelectedId("");
                            toast.success(response.data.message);
                        } else {
                            toast.error(response.data.message);
                        }
                    } else {
                        toast.error("خطا در اتصال به سرور");
                    }
                })
                .catch(error => {
                    setLoading(false);
                    toast.error("خطا در اتصال به سرور");
                })
        } else {
            toast.error("هیچ موردی انتخاب نشده است")
        }
    }
    const handle_set_settings=(showPrice=true)=>{
        if (!!percentList){
            setLoading(true);
            const formattedData = percentList
                .map(item => `${item.from}:${item.to}:${item.percent}`)
                .join(',');

            console.log(formattedData);
            const data={
                token : sessionStorage.getItem("token"),
                percent : formattedData,
                showPrice : showPrice,
            }
            axios.post("https://khaneaghsat.co/api/setsettings.php",data)
                .then(response=>{
                    setLoading(false);
                    if (response.status === 200){
                        if (response.data.error === false){
                            toast.success(response.data.message);
                            setDialogPercent(false);
                        }
                        else {
                            toast.error(response.data.message);
                        }
                    }else {
                        toast.error("خطا در اتصال به سرور");
                    }
                })
        }else {
            setLoading(false);
            toast.error("میزان درصد نمیتواند خالی باشد");
        }
    }
    const handle_add_installment=()=>{
        if (!!installment){
            setLoading(true);
            const data={
                token : sessionStorage.getItem("token"),
                title : installment,
                section: "installment",
            }
            axios.post("https://khaneaghsat.co/api/insert.php",data)
                .then(response=>{
                    if (response.status === 200){
                        if (response.data.error === false){
                            toast.success(response.data.message);
                            setLoading(false);
                            setUpdate(true);
                            setInstallment("");
                        }
                    }else {
                        toast.error("خطا در اتصال به سرور")
                    }
                })
        }else {
            toast.error("هیچ ایتمی انتخاب نشده است")
        }
    }
    const handleChange = (index, field, value) => {
        const newPercent = [...percentList];
        newPercent[index][field] = value;
        setPercentList(newPercent);
    };
    return (
        <div>
            {sessionStorage.getItem("token") === "" ? <Redirect/> : ''}
            <Grid container spacing={2} sx={{marginTop: 0}}>
                <Grid item xs={12} md={12} sx={{backgroundColor: "#ffffff", minHeight: "100vh"}}>
                    <Appbar
                        showPrice={showPriceSwitch}
                        setShowPrice={(status)=>{
                            setShowPriceSwitch(status);
                            handle_set_settings(status);
                        }}
                        setDialogPecent={setDialogPercent}
                        setDialogInstallment={setDialogInstallment}/>
                    <Box sx={{padding: 2}} dir={"rtl"}>
                        {productList.map((item, index) => (
                            <>
                                <h3 style={{display:"inline"}}>{item?.title}</h3>&nbsp;
                                <Button
                                    size={"small"}
                                    color={"error"}
                                    sx={{marginLeft:1}}
                                    onClick={()=>handle_delete(item?.id,"product")}
                                    variant="outlined">
                                    حذف
                                </Button>
                                <Button
                                    size={"small"}
                                    onClick={()=>{
                                        setDialogModel(true);
                                        setSelectedId(item?.id);
                                    }}
                                    variant="outlined">افزودن مدل</Button>

                                <Box sx={{marginRight: 3}}>
                                    <div></div>
                                    <div style={{margin: "15px"}}>
                                        {item?.model?.map((model) => (
                                            <div
                                                style={{
                                                    backgroundColor: "#e1e1e1",
                                                    margin: "10px"
                                                }}>
                                                <div style={{
                                                    borderRadius: "7px 7px 0 0",
                                                    padding: "7px",
                                                }}>
                                                    <div>
                                                        مدل : {model?.title}&nbsp;
                                                        <Button
                                                            size={"small"}
                                                            color={"error"}
                                                            sx={{marginLeft:1}}
                                                            onClick={()=>handle_delete(model?.id,"model")}
                                                            variant="outlined">
                                                            حذف
                                                        </Button>
                                                        <Button
                                                            size={"small"}
                                                            onClick={() => {
                                                                setSelectedId(model?.id);
                                                                setDialogStorage(true);
                                                            }}
                                                            variant="outlined">افزودن حافظه</Button>
                                                    </div>
                                                </div>
                                                {model?.storage?.map(storage => (
                                                    <div style={{padding: "10px",marginRight:"10px"}}>
                                                        <div style={{padding: "5px"}}>
                                                            حافظه : {storage?.title}&nbsp;
                                                            <Button
                                                                size={"small"}
                                                                color={"error"}
                                                                sx={{marginLeft:1}}
                                                                onClick={()=>handle_delete(storage?.id,"storage")}
                                                                variant="outlined">
                                                                حذف
                                                            </Button>
                                                            <Button
                                                                size={"small"}
                                                                onClick={()=>{
                                                                    setSelectedId(storage?.id);
                                                                    setDialogColor(true);
                                                                }}
                                                                variant="outlined">
                                                                افزودن رنگ بندی
                                                            </Button>

                                                        </div>
                                                        <div>
                                                            <div>
                                                                {storage?.color.map(color => (
                                                                    <Chip
                                                                        dir={"ltr"}
                                                                        sx={{margin: 1}}
                                                                        label={
                                                                            <>

                                                                                <div dir={"rtl"}>
                                                                                    <IconButton aria-label="delete">
                                                                                        <EditIcon
                                                                                            onClick={()=>{
                                                                                                setSelectedId(color?.id);
                                                                                                setColorTitle(color?.title);
                                                                                                setPrice(color?.price);
                                                                                                setMinPrePrice(color?.minpre);
                                                                                                setMaxPrePrice(color?.maxpre);
                                                                                                setStepPrePrice(color?.steppre);
                                                                                                setColorCode(color?.code);
                                                                                                setDialogEditColor(true);
                                                                                            }}
                                                                                            color={"info"}
                                                                                            sx={{fontSize:"18px"}}/>
                                                                                    </IconButton>
                                                                                    رنگ : {color?.title}&nbsp;
                                                                                    <div
                                                                                        style={{
                                                                                            width: "20px",
                                                                                            height: "20px",
                                                                                            borderRadius: "50%",
                                                                                            backgroundColor: color?.code,
                                                                                            display: "inline-block",
                                                                                            marginBottom: "-6px",
                                                                                        }}>
                                                                                    </div>&nbsp;
                                                                                    قیمت : {Number(color?.price).toLocaleString()}&nbsp;
                                                                                    حداقل پیش پرداخت  : {Number(color?.minpre).toLocaleString()}&nbsp;
                                                                                    حداکثر پیش پرداخت : {Number(color?.maxpre).toLocaleString()}&nbsp;
                                                                                    بازه پیش پرداخت : {Number(color?.steppre).toLocaleString()}&nbsp;


                                                                                </div>
                                                                            </>
                                                                        }
                                                                        onDelete={()=>handle_delete(color.id,"color")}/>
                                                                ))}
                                                            </div>
                                                        </div>

                                                    </div>
                                                ))}
                                            </div>
                                        ))}
                                    </div>
                                </Box>
                            </>
                        ))}
                        <Button
                            size={"small"}
                            onClick={()=>{
                                setDialogProduct(true);
                            }}
                            fullWidth
                            variant="contained">
                            افزودن محصول جدید
                        </Button>
                    </Box>
                </Grid>
            </Grid>
            <Dialog
                open={dialogEditColor}
                dir={"rtl"}
                onClose={() => setDialogEditColor(false)}>
                <DialogTitle>
                    {"ویرایش رنگبندی"}
                </DialogTitle>
                <DialogContent>
                    <div>
                        <TextField
                            placeholder={"عنوان رنگ"}
                            value={colorTitle}
                            size="small"
                            autoComplete={"off"}
                            onChange={(e) => setColorTitle(e.target.value)}
                            variant="outlined"/>
                    </div>
                    <div>
                        <TextField
                            placeholder={"مبلغ"}
                            value={price}
                            size="small"
                            sx={{marginTop:1}}
                            autoComplete={"off"}
                            onChange={(e) => setPrice(e.target.value)}
                            variant="outlined"/>
                    </div>
                    <div>
                        <TextField
                            placeholder={"حداقل پیش پرداخت"}
                            value={minPrePrice}
                            size="small"
                            sx={{marginTop:1}}
                            autoComplete={"off"}
                            onChange={(e) => setMinPrePrice(e.target.value)}
                            variant="outlined"/>
                    </div>
                    <div>
                        <TextField
                            placeholder={"حداکثر پیش پرداخت"}
                            value={maxPrePrice}
                            size="small"
                            sx={{marginTop:1}}
                            autoComplete={"off"}
                            onChange={(e) => setMaxPrePrice(e.target.value)}
                            variant="outlined"/>
                    </div>
                    <div>
                        <TextField
                            placeholder={"بازه پیش پرداخت"}
                            value={stepPrePrice}
                            size="small"
                            sx={{marginTop:1}}
                            autoComplete={"off"}
                            onChange={(e) => setStepPrePrice(e.target.value)}
                            variant="outlined"/>
                    </div>
                    <div>
                        <TextField
                            value={colorCode}
                            size="small"
                            fullWidth
                            type={"color"}
                            sx={{marginTop:1}}
                            autoComplete={"off"}
                            onChange={(e) => setColorCode(e.target.value)}
                            variant="outlined"/>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDialogEditColor(false)}>بستن</Button>
                    <Button onClick={handle_edit_color} autoFocus>
                        ویرایش
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={dialogColor}
                dir={"rtl"}
                onClose={() => setDialogColor(false)}>
                <DialogTitle>
                    {"افزودن رنگبندی"}
                </DialogTitle>
                <DialogContent>
                    <div>
                        <TextField
                            placeholder={"عنوان رنگ"}
                            value={colorTitle}
                            size="small"
                            autoComplete={"off"}
                            onChange={(e) => setColorTitle(e.target.value)}
                            variant="outlined"/>
                    </div>
                    <div>
                        <TextField
                            placeholder={"مبلغ"}
                            value={price}
                            size="small"
                            sx={{marginTop:1}}
                            autoComplete={"off"}
                            onChange={(e) => setPrice(e.target.value)}
                            variant="outlined"/>
                    </div>
                    <div>
                        <TextField
                            placeholder={"حداقل پیش پرداخت"}
                            value={minPrePrice}
                            size="small"
                            sx={{marginTop:1}}
                            autoComplete={"off"}
                            onChange={(e) => setMinPrePrice(e.target.value)}
                            variant="outlined"/>
                    </div>
                    <div>
                        <TextField
                            placeholder={"حداکثر پیش پرداخت"}
                            value={maxPrePrice}
                            size="small"
                            sx={{marginTop:1}}
                            autoComplete={"off"}
                            onChange={(e) => setMaxPrePrice(e.target.value)}
                            variant="outlined"/>
                    </div>
                    <div>
                        <TextField
                            placeholder={"بازه پیش پرداخت"}
                            value={stepPrePrice}
                            size="small"
                            sx={{marginTop:1}}
                            autoComplete={"off"}
                            onChange={(e) => setStepPrePrice(e.target.value)}
                            variant="outlined"/>
                    </div>
                    <div>
                        <TextField
                            value={colorCode}
                            size="small"
                            fullWidth
                            type={"color"}
                            sx={{marginTop:1}}
                            autoComplete={"off"}
                            onChange={(e) => setColorCode(e.target.value)}
                            variant="outlined"/>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDialogColor(false)}>بستن</Button>
                    <Button onClick={handle_add_color} autoFocus>
                        افزودن
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={dialogModel}
                dir={"rtl"}
                onClose={() => setDialogModel(false)}>
                <DialogTitle>
                    {"افزودن مدل"}
                </DialogTitle>
                <DialogContent>
                    <TextField
                        placeholder={"عنوان مدل"}
                        value={modelTitle}
                        size="small"
                        autoComplete={"off"}
                        onChange={(e) => setModelTitle(e.target.value)}
                        variant="outlined"/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDialogModel(false)}>بستن</Button>
                    <Button onClick={handle_add_model} autoFocus>
                        افزودن
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={loading}
                scroll="paper">
                <CircularProgress sx={{margin: 2}}/>
            </Dialog>
            <Dialog
                open={dialogStorage}
                dir={"rtl"}
                onClose={() => setDialogStorage(false)}>
                <DialogTitle>
                    {"افزودن حافظه"}
                </DialogTitle>
                <DialogContent>
                    <TextField
                        placeholder={"عنوان حافظه"}
                        value={storageTitle}
                        autoComplete={"off"}
                        size="small"
                        onChange={(e) => setStorageTitle(e.target.value)}
                        variant="outlined"/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDialogStorage(false)}>بستن</Button>
                    <Button onClick={handle_add_storage} autoFocus>
                        افزودن
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={dialogProduct}
                dir={"rtl"}
                onClose={() => setDialogProduct(false)}>
                <DialogTitle>
                    {"افزودن محصول"}
                </DialogTitle>
                <DialogContent>
                    <TextField
                        placeholder={"عنوان محصول"}
                        value={productTitle}
                        autoComplete={"off"}
                        size="small"
                        onChange={(e) => setProductTitle(e.target.value)}
                        variant="outlined"/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDialogProduct(false)}>بستن</Button>
                    <Button onClick={handle_add_product} autoFocus>
                        افزودن
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={dialogPercent}
                dir={"rtl"}
                onClose={() => setDialogPercent(false)}>
                <DialogTitle>
                    {"درصد سود"}
                </DialogTitle>
                <DialogContent>
                    <div>
                        {percentList.map((item, index) => {
                            return (
                                <Grid container spacing={1} key={index}>
                                    <Grid item xs={4}>
                                        <div>از</div>
                                        <TextField
                                            placeholder={"از"}
                                            value={item.from}
                                            size="small"
                                            type={"number"}
                                            autoComplete={"off"}
                                            onChange={(e) => handleChange(index, 'from', e.target.value)}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <div>تا</div>
                                        <TextField
                                            placeholder={"تا"}
                                            value={item.to}
                                            type={"number"}
                                            size="small"
                                            autoComplete={"off"}
                                            onChange={(e) => handleChange(index, 'to', e.target.value)}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <div>درصد</div>
                                        <TextField
                                            placeholder={"میزان درصد"}
                                            value={item.percent}
                                            size="small"
                                            autoComplete={"off"}
                                            onChange={(e) => handleChange(index, 'percent', e.target.value)}
                                            variant="outlined"
                                        />
                                    </Grid>
                                </Grid>
                            );
                        })}

                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDialogPercent(false)}>بستن</Button>
                    <Button onClick={()=>handle_set_settings(showPriceSwitch)} autoFocus>
                        ویرایش
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={dialogInstallment}
                dir={"rtl"}
                onClose={() => setDialogInstallment(false)}>
                <DialogTitle>
                    {"تعداد اقساط"}
                </DialogTitle>
                <DialogContent>
                    <div>
                        <TextField
                            placeholder={"قسط"}
                            value={installment}
                            size="small"
                            autoComplete={"off"}
                            onChange={(e) => setInstallment(e.target.value)}
                            variant="outlined"/>
                    </div>
                    {installmentList.map(items=>(
                        <div style={{margin:"10px"}}>
                            <Chip sx={{width:"100%",justifyContent:"right"}} dir={"ltr"} label={items.title} onDelete={()=>handle_delete(items.id,"installment")} />
                        </div>
                    ))}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDialogPercent(false)}>بستن</Button>
                    <Button onClick={handle_add_installment} autoFocus>افزودن</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={loading}
                scroll="paper">
                <CircularProgress sx={{margin: 2}}/>
            </Dialog>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={true}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    )
}
export default Dashboard;